

import { Vue, Component, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Machine } from '@/types/material.d'
import { idCardNo, phone, twoDecimal } from '@/utils/validate'

@Component
export default class extends Vue {
  @Ref('form') readonly form!: ElForm
  formData: any = {
    machineryName: '', // 机械名称
    specificationModel: '', // 规格/型号
    supplierId: '', // 所属供应商
    supplierName: '', // 所属供应商
    project: null, // 所属项目
    projectName: '', // 项目名称
    pricingMethod: '', // 计价方式
    unitPrice: '', // 含税单价
    whetherOil: '2', // 是否含油
    driverName: '', // 驾驶员姓名
    identityCard: '', // 身份证号码
    mobileNumber: '', // 手机号
    engineNumber: '', // 发动机编号
    remarks: '' // 备注
  }

  supplierData = []

  rules = {
    machineryName: [
      { required: true, message: '请输入机械名称', trigger: 'blur' }
    ],
    specificationModel: [
      { required: true, message: '请输入规格型号', trigger: 'blur' }
    ],
    supplierId: [
      { required: true, message: '请选择所属供应商', trigger: 'blur' }
    ],
    pricingMethod: [
      { required: true, message: '请输入计价方式', trigger: 'blur' }
    ],
    unitPrice: [
      { required: true, message: '请输入含税单价', trigger: ['blur', 'change'] },
      { validator: twoDecimal, trigger: ['blur', 'change'] }
    ],
    identityCard: [
      { validator: idCardNo }
    ],
    mobileNumber: [
      { validator: phone }
    ]
  }

  get machineryId () {
    return this.$route.query.machineryId as string
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    if (this.machineryId) {
      this.loadData()
    }
    this.loadSupplierType()
  }

  loadData () {
    this.$axios.get<Machine>(this.$apis.material.selectMachineryByMachineryId, {
      machineryId: this.machineryId
    }).then(res => {
      this.formData = res || {}
    })
  }

  loadSupplierType () {
    this.$axios.get(this.$apis.material.selectErpSupplierByPage).then(res => {
      this.supplierData = res.list
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        if (this.formData.project === '') {
          this.formData.project = null
        }
        const url = this.machineryId ? this.$apis.material.updateMachinery : this.$apis.material.insertMachinery
        this.$axios.post(url, this.formData).then(() => {
          this.$router.back()
        })
      }
    })
  }
}
